import React, { useEffect } from "react"
import LayoutComponent from "../components/LayoutComponent/LayoutComponent"
import MenuComponent from "../components/MenuComponent/MenuComponent"
import "../styles/global.scss"
import SEO from "../components/seo"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import { unloadRecaptcha } from "../utils/Captcha"
import VideoComponent from "../components/VideoComponent/VideoComponent"

const Archiv = () => {
  useEffect(() => {
    unloadRecaptcha()
  }, [])

  const images = [
    {
      original: "/archiv/Gruppenbild_2015.jpg",
      thumbnail: "/archiv/Gruppenbild_2015.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2014.jpg",
      thumbnail: "/archiv/Gruppenbild_2014.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2013.jpg",
      thumbnail: "/archiv/Gruppenbild_2013.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2012.jpg",
      thumbnail: "/archiv/Gruppenbild_2012.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2012_JHV.jpg",
      thumbnail: "/archiv/Gruppenbild_2012_JHV.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2010_Sommer.jpg",
      thumbnail: "/archiv/Gruppenbild_2010_Sommer.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2010_Bout.jpg",
      thumbnail: "/archiv/Gruppenbild_2010_Bout.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2009_USCar.jpg",
      thumbnail: "/archiv/Gruppenbild_2009_USCar.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2009_Bout.jpg",
      thumbnail: "/archiv/Gruppenbild_2009_Bout.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2009_Bout_Erster.jpg",
      thumbnail: "/archiv/Gruppenbild_2009_Bout_Erster.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2008.jpg",
      thumbnail: "/archiv/Gruppenbild_2008.jpg",
    },
    {
      original: "/archiv/17906-roller-skates-pv.jpg",
      thumbnail: "/archiv/17906-roller-skates-pv.jpg",
    },
    {
      original: "/archiv/Gifs/FOOTWORK_converted.gif",
      thumbnail: "/archiv/Gifs/FOOTWORK_converted.gif",
    },
    {
      original: "/archiv/Gifs/FOOTWORK2_converted.gif",
      thumbnail: "/archiv/Gifs/FOOTWORK2_converted.gif",
    },
    {
      original: "/archiv/Gifs/FUNPUSH_converted.gif",
      thumbnail: "/archiv/Gifs/FUNPUSH_converted.gif",
    },
    {
      original: "/archiv/Gifs/liegestuetz_converted.gif",
      thumbnail: "/archiv/Gifs/liegestuetz_converted.gif",
    },
    {
      original: "/archiv/Gifs/RENNEN_converted.gif",
      thumbnail: "/archiv/Gifs/RENNEN_converted.gif",
    },
  ]

  return (
    <LayoutComponent isEnglish languageSwitchSubpage="archiv">
      <SEO title="Archive" />
      <MenuComponent selected={0} isEnglish />
      <div style={{ marginTop: "50px" }}>
        <ImageGallery thumbnailPosition={"left"} items={images} />
      </div>

      <p style={{ textAlign: "center" }}>
        <a href="#">Youtube Playlist</a>
      </p>

      <VideoComponent title={"sportlehrehrung_2020.mp4"} />
      <VideoComponent title={"sommerfest.mp4"} />
      <VideoComponent title={"tshirt.mp4"} />

      <div className="archive-article">
        <h2>Erfolg 2020</h2>
        <p>
          Our two players Carl Taylor (aka Bloodred Kelly) and Sören Schneemann
          (aka Brad Hit) were venerated by the city of Ludwigsburg for being
          part of the German national Team last year. With the men‘s national
          team they won the nations‘ cup in Frankfurt in February 2020 with a
          smashing victory. Also competing in the tournament were the teams from
          Italy and Denmark, all teams playing each other twice. Our guys
          contributed to the superior matches of the German team, celebrating
          first place in the tournament. Both were also nominated to compete in
          the world championship which unfortunately had to be cancelled due to
          the Corona pandemic.
          <br />
          <br />
          Due to Corona, the celebration of the succesful athletes can‘t be held
          in person this year, so the MTV made a video of all the members who
          are venerated for their successes in the past year:{" "}
          <a href="https://www.youtube.com/watch?v=mswOqfntVys\">
            https://www.youtube.com/watch?v=mswOqfntVys
          </a>
        </p>
        <div className="img-container">
          <img src="/archiv/sportlerehrung_cut.png" alt="Sportlerehrung" />
        </div>
      </div>
      <div className="archive-article">
        <h2>Brezel Bash 2019</h2>
        <div className="img-container">
          <img src="/archiv/brezelbash.png" alt="Brezelbash" />
        </div>
        <p>
          At the bavarian championships in Regensburg in 2019, there was a mixed
          game that we took part in. Four of us were happy to compete in the
          „Brezel Bash“! Trinity was giving her rollerderby debut, while
          Desperados Chainsaw, Funny Commander and Mighty Mimi could gain some
          more match experience. Even if it meant getting up really early- we
          had lots of fun!
        </p>
      </div>
    </LayoutComponent>
  )
}

export default Archiv
